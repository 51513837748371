<template>
  <v-row class="mt-3" id="table-selector">
    <v-col class="px-0">
      <v-btn class="float-end orange lighten-4 orange--text"
             x-large
             elevation="0"
             icon
             outlined
             tile
             @click="moveLeft"
             :disabled="0 === this.selectedItem"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>
    <v-col class="px-0" cols="2">
      <v-text-field
          :value="value"
          :readonly="true"
          outlined
          tile
      />
    </v-col>
    <v-col class="px-0">
      <v-btn
          class="float-start orange lighten-4 orange--text"
          large elevation="0"
          icon
          outlined
          tile
          x-large
          @click="moveRight"
          :disabled="this.items.length - 1 === this.selectedItem"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ArrowSelector",
  props: ['value','items'],
  data(){
    return {
      selectedItem: 0
    }
  },
  methods:{
    moveRight(){
      this.selectedItem++
      this.$emit('input', this.items[this.selectedItem])
    },
    moveLeft(){
      this.selectedItem--
      this.$emit('input', this.items[this.selectedItem])
    }
  }
}
</script>

<style lang="scss">
#table-selector {
  .v-input__slot {
    border-radius: 0;
    fieldset {
      max-height: 57px;
    }
    input{
      text-align: center;
    }
  }
}

</style>