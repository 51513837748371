<template>
  <div>
    <!--    <stepper-custom v-if="!$root.isMobile()" :src="eventData" :step="2"></stepper-custom>-->
    <stepper-custom v-if="!$root.isMobile()"  :step="2"></stepper-custom>
    <v-card color="#0F4A6D" class="hidden-md-and-up" tile elevation="0">
      <v-card-text class="white--text text-uppercase text-center">Selecciona tus asientos</v-card-text>
    </v-card>
    <v-container>
      <v-row class="justify-space-between">
        <v-col
            cols="12"
            sm="12"
            md="6"
            lg="4"
            order-sm="1"
            order-md="2"
            order-lg="2"
            v-if="scheduleData"
        >
          <v-card color="#F3F3F3"  elevation="0"  >
            <div v-if="$root.isMobile()">
              <v-card-text class="font-weight-bold">
                <v-icon>mdi-account-group-outline</v-icon>
                <div class="text-p ml-2 d-inline">{{people}} Personas <span v-if="$store.state.amphiEvents.length">({{getEventPrice*people}} €)</span></div>
              </v-card-text>
              <v-card-text class="font-weight-bold">
                <v-icon>mdi-calendar-range</v-icon>
                <div class="text-p ml-2 d-inline"> {{ getEventDate().date }}</div>
              </v-card-text>
              <v-card-text class="font-weight-bold">
                <v-icon>mdi-timer-outline</v-icon>
                <div class="text-p ml-2 d-inline"> {{ getEventDate().hour }}</div>
              </v-card-text>
            </div>
            <div v-else>
              <v-card-text class="font-weight-bold text-uppercase pb-0">Fecha</v-card-text>
              <v-card-subtitle class="pt-0">{{ getEventDate().date }}</v-card-subtitle>
              <v-card-text class="font-weight-bold text-uppercase pb-0">Horario</v-card-text>
              <v-card-subtitle class="pt-0">{{ getEventDate().hour }}</v-card-subtitle>
              <v-card-text class="font-weight-bold text-uppercase pb-0">Personas A Asistir</v-card-text>
              <v-card-subtitle class="pt-0">{{ people }}</v-card-subtitle>
            </div>
          </v-card>
          <v-card
              class="my-3"
              color="#F3F3F3"
              elevation="0"
              v-if="!$root.isMobile()">
            <v-container fluid>
            <v-card-text class="text-p text-uppercase primary--text pb-0"> Mesa Seleccionada</v-card-text>
            <v-card-subtitle class="pt-0">{{ selectedTable }}</v-card-subtitle>
            <v-card-text class="text-p text-uppercase primary--text pb-0"> Capacidad De la Mesa</v-card-text>
            <v-card-subtitle class="text-uppercase pt-0 mb-5">{{ tableCapacity }} personas</v-card-subtitle>
            <v-btn class="primary white--text mx-auto" block tile depressed @click="goToRemember">
              <v-icon left>mdi-cart-outline</v-icon>
              Confirmar mesa
            </v-btn>
            </v-container>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="6"
            lg="8"
            order-sm="2"
            order-md="1"
            order-lg="1"

        >
          <div v-if="selectedTable">
            <amphitheater :selected="selectedTable" @change="value => selectedTable = value" :active="selectTables" width="90%" height="100%"></amphitheater>
            <!--      <img class="d-flex my-5 mx-auto" src="../assets/amphitheater/amphi.svg"/>-->
          </div>
        </v-col>
      </v-row>
      <div v-if="$root.isMobile()">
        <arrow-selector :items="selectTables"  v-model="selectedTable"></arrow-selector>
        <v-btn class="orange white--text" block tile x-large depressed @click="goToRemember">
          Confirmar mesa
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-container>

  </div>
</template>

<script>
import ArrowSelector from "@/components/ArrowSelector";
import Amphitheater from "@/components/Amphitheater";
import {AmphiEvents, Reservation} from "@/services/api";
import StepperCustom from "@/components/StepperCustom";
export default {
  name: "SelectTable",
  components: {StepperCustom, Amphitheater, ArrowSelector},
  data(){
    return {
      tables:[],
      selectTables:[],
      selectedTable: null,
      scheduleData: null,
      tableCapacity: null
    }

  },
  beforeCreate() {
    if(!this.$store.state.amphiEvents.length){
      AmphiEvents(null).getEvents()
          .then(response => response.data)
          .then(items => {
            this.$store.commit('setEvent', items.data)
          })
    }
  },
  created() {
    if(this.session){
      this.loadLogIn()
    }
  },
  mounted() {
    this.getTables()
    this.getSchedule()
  },
  metaInfo() {
    return {
      title: `Seleccion Asientos | ${this.eventData ? this.eventData.name : ''}`,
      meta: [
        {name: 'description', content: 'Saca tus entrdadas de forma facil y dinamica'},
      ]
    }
  },
  methods:{
    goToRemember(){
      let table = this.getTable()
      const user_id = this.user ? this.user.id : 1
      this.requestReservation.create(
          {
            user_id: user_id,
            amphi_table_id: table.id,
            event_schedule_id: this.scheduleId
          }
      ).then(
          response => {
            this.$store.commit('setReservation', response.data.data)
            if(this.isMobile()){
              this.$router.push({name:'MobileRemember'})
            }else{
              this.$router.push({name:'Payment'})

            }
          }
      )

    },
    getTables(){
      this.requestAEvents.getTables(this.scheduleId, {amount: this.people})
          .then(response =>
          {
            let tables = response.data.data
            let arrTables = this.tables
            this.selectTables = tables.map(function(elem) {
              let tableObject = {
                capacity: elem.capacity,
                value: elem.name,
                id: elem.id
              }
              arrTables.push(tableObject)
              return elem.name;
            });
            this.selectedTable = this.selectTables[0]
          })

    },
    getTable(){
      return this.tables.find( elem => elem.value === this.selectedTable)
    },
    getSchedule(){
      this.requestAEvents.getEventSchedule(this.scheduleId)
          .then(
              response => {
                console.log(response.data)
                this.scheduleData = response.data.data
                this.getEventDate()
              }
          )
          .finally( ()=>
              this.$store.commit('setLoading', false)
          )
    },
    getEventDate() {
      let date = this.scheduleData.schedule.split('T')
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
      let eventDate = new Date(date[0])
      return {
        date: eventDate.toLocaleString('es-es', options),
        hour: date[1].substr(0, 5)
      }
    },
    loadLogIn(){
      const user = {
        access_token : this.session,
        token_type: 'Bearer'
      }
      this.$store.commit('setUser', user)
    }
  },
  watch:{
    selectedTable(newval){
      this.tableCapacity = this.tables.find( elem => elem.value === newval).capacity

    }
  },
  computed:{
    requestAEvents(){
      return AmphiEvents(this.user.token_type,this.user.access_token)
    },
    requestReservation(){
      return Reservation(this.user.token_type,this.user.access_token)
    },
    getEventPrice(){
      return this.eventData.price
    },
    eventData(){
      let amphiEvents = this.$store.state.amphiEvents;
      return amphiEvents.find(elem => elem.id === (this.scheduleData ? this.scheduleData.amphi_event_id: ''))
    },
    scheduleId(){
      return this.$route.params.id || this.$route.query.id
    },
    people(){
      return this.$route.params.people || this.$route.query.people
    },
    user(){
      return this.$store.state.user
    },
    session(){
      return this.$route.query.sessionid || '1|jvUAY0EqQJbGtAAHEqzpdZmf59EfidrJINVr87nA'
    }
  },


}
</script>

<style scoped>

</style>